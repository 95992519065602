<template>
  <div class="container">
    <div v-if="showFilters" class="justify-center items-center flex w-full overflow-x-auto max-w-fit mx-auto">
      <div class="flex gap-x-3 my-4 px-4 w-full">
        <button
          class="text-xs border border-primary-400 rounded-md py-2 px-1 min-w-[100px] hover:bg-primary-1000 hover:text-primary-100"
          :class="{
            'bg-primary-1000 text-primary-100 font-bold': activeCategory === 'all',
            'text-primary-1000 font-medium': activeCategory !== 'all',
          }"
          @click="loadData({ name: 'all', product: products })"
        >
          {{ t('shop.all') }}
        </button>
        <button
          v-for="category in categories"
          class="text-xs border border-primary-400 rounded-md py-2 px-1 min-w-[120px] hover:bg-primary-1000 hover:text-primary-100"
          :class="{
            'bg-primary-1000 text-primary-100 font-bold': activeCategory === category.name,
            'text-primary-1000 font-medium': activeCategory !== category.name,
          }"
          @click="loadData(category)"
        >
          {{ category.name }}
        </button>
      </div>
    </div>

    <div class="relative">
      <Swiper
        :key="activeCategory"
        class="mb-6 product-slider"
        :modules="[SwiperNavigation]"
        :slides-per-view="1.55"
        :slides-per-group="1"
        space-between="0"
        :navigation="{ enabled: true }"
        :loop="true"
        :breakpoints="{
          768: { slidesPerView: 3.15, slidesPerGroup: 3 },
          1024: { slidesPerView: 3.15, slidesPerGroup: 3 },
          1280: { slidesPerView: 4, slidesPerGroup: 4 },
        }"
      >
        <SwiperSlide v-for="product in products.filter((prod) => prod.stock > 0)" :key="product.id" class="p-2">
          <SharedProductCard :product="product" />
        </SwiperSlide>
      </Swiper>
    </div>

    <CommonButton
      v-if="showViewMore"
      class="w-full justify-center mx-auto max-w-fit"
      variant="outline"
      color="primary"
      :to="generateLink('/shop/')"
    >
      {{ t('common.view_more') }}
    </CommonButton>
  </div>
</template>

<script setup lang="ts">
import type { HomeCategory } from '~/interfaces/Home';
import type { Product } from '~/interfaces/Product';

const activeCategory = ref('all');

const { t, locale } = useI18n();
const emit = defineEmits(['loadData']);
const gtm = useGtm();

const props = defineProps({
  showFilters: {
    type: Boolean,
    default: true,
  },
  showViewMore: {
    type: Boolean,
    default: true,
  },
  isProductsLoading: {
    type: Boolean,
  },
  products: {
    type: Array as PropType<Product[]>,
    required: true,
  },
  categories: {
    type: Array as PropType<HomeCategory[]>,
    required: false,
  },
});

const loadData = (category: HomeCategory) => {
  activeCategory.value = category.name || 'all';

  gtm?.trackEvent({
    event: 'view_item_list',
    ecommerce: {
      item_list_id: activeCategory.value,
      item_list_name: activeCategory.value,
      items: props.products.map((product) => ({
        item_id: product.id,
        item_sku: product.sku,
        item_name: product.meta_title?.en,
        item_category: product.categories?.[0]?.name,
        item_category2: product.categories?.[1]?.name,
        item_category3: product.categories?.[2]?.name,
        price: formatPrice(product.price),
        discount: formatPrice(product.sale_price),
        quantity: 1,
        item_list_id: activeCategory.value,
        item_list_name: activeCategory.value,
      })),
    },
  });

  if (category.name === 'all') {
    emit('loadData', { name: 'all', product: props.products });
  } else {
    emit('loadData', category);
  }
};
</script>

<style scoped>
@media (min-width: 1024px) {
  .product-slider {
    position: unset;
    margin-left: 50px;
    margin-right: 50px;
  }

  .product-slider .swiper-slide {
    min-width: 311px;
    max-width: fit-content;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
